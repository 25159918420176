import(/* webpackMode: "eager" */ "/vercel/path0/frontend/apps/v1.lighting-illusions/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/apps/v1.lighting-illusions/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/apps/v1.lighting-illusions/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/apps/v1.lighting-illusions/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/fonts/GothamRnd-Book.otf\",\"weight\":\"400\"},{\"path\":\"../../public/fonts/GothamRnd-BookIta.otf\",\"weight\":\"400\",\"style\":\"italic\"},{\"path\":\"../../public/fonts/GothamRnd-Medium.otf\",\"weight\":\"500\"},{\"path\":\"../../public/fonts/GothamRnd-MedIta.otf\",\"weight\":\"500\",\"style\":\"italic\"}],\"variable\":\"--font-gotham\"}],\"variableName\":\"gotham\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/apps/v1.lighting-illusions/node_modules/swiper/swiper.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/apps/v1.lighting-illusions/public/logo-white.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/apps/v1.lighting-illusions/src/app/globals.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/apps/v1.lighting-illusions/src/common/components/layout/headerv2/components/HeaderCompanyStats.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/packages/common.v1/src/common/components/analytics/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/packages/common.v1/src/common/components/footer/components/FooterCopyright.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/packages/common.v1/src/common/components/footer/components/FooterNewsletter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/packages/common.v1/src/common/components/GlobalDataSetter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/packages/common.v1/src/common/components/headerv2/components/desktop/components/Items/MenuItemHeader.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/packages/common.v1/src/common/components/headerv2/components/desktop/components/Items/MenuItemLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/packages/common.v1/src/common/components/hoc/AuthSessionProvider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/packages/common.v1/src/common/components/Icons.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/packages/common.v1/src/common/components/layout/banners.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/packages/common.v1/src/common/components/layout/header/mobile/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/packages/common.v1/src/common/components/layout/headerv2/components/HeaderInfo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/packages/common.v1/src/common/components/layout/headerv2/desktop/components/NavigationSearch.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/packages/common.v1/src/common/components/layout/headerv2/desktop/components/NavigationTopBar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/vercel/path0/frontend/packages/common.v1/src/common/hooks/store/provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Client"] */ "/vercel/path0/frontend/packages/common.v1/src/common/utils/Client.tsx");
